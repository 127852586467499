import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import BlogPostTags from '../components/blog-post-tags'

const Tags = ({
  pageContext,
  data,
  location
}) => {
  const { tag } = pageContext
  const { edges } = data.allMarkdownRemark

  return (
    <div>
      <Layout location={location} title={data.site.siteMetadata.title}>
        <h2>
          #{tag} の記事
        </h2>
        <ul className='post-list-item'>
          {edges.map(({ node }) => {
            const { slug } = node.frontmatter
            const { title } = node.frontmatter
            const { date } = node.frontmatter
            const { tags } = node.frontmatter
            return (
              <li key={slug}>
                <header>
                  <h2 className='post-list-item'>
                    <Link to={slug} itemProp='url'>
                      <span itemProp='headline'>{title}</span>
                    </Link>
                  </h2>
                  <div>
                    <small>{date}</small>
                  </div>
                  <BlogPostTags tags={tags} />
                </header>
              </li>
            )
          })}
        </ul>
        <Link
          to='/tags/'
        >
          All tags
        </Link>
      </Layout>
    </div>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
              slug: PropTypes.string.isRequired
            })
          })
        }).isRequired
      )
    })
  })
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            slug
            date(formatString: "YYYY.MM.DD")
            tags
          }
        }
      }
    }
  }
`
